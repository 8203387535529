import dynamic from 'next/dynamic'
import { useSearchParams } from 'next/navigation'
import { memo, useState } from 'react'

import { useGetBannerBlockInfoQuery } from '@redux/features/homePage/homePage.api'
import { useGetSearchFormStationQuery } from '@redux/features/search/search.api'
import { skipToken } from '@reduxjs/toolkit/query/react'

import { SubscribeEvents } from '@Types/common/subscribeEvents'
import getStationFormValues from '@components/mainPage/mainBlock/searchTrains/search/searchForm/utils/getStationFormValues'
import { Wrapper } from '@components/map/MapWithSearchStations/styles'
import { QueryParameters } from '@constants/routes/queryParameters'
import useIsRoutePage from '@hooks/routes/useIsRoutePage/useIsRoutePage'
import useGetNodeId from '@hooks/useGetNodeId/useGetNodeId'
import { useSubscribe } from '@hooks/useSubscribe/useSubscribe'

import type { StationsForMapType } from './types/stationsForMapType'

const DynamicMap = dynamic(() => import('./components/DynamicMap'))
const StaticMap = dynamic(() => import('./components/StaticMap'))

const MapChart = () => {
  const params = useSearchParams()
  const isRoutePage = useIsRoutePage()
  const nodeId = useGetNodeId(!isRoutePage)

  const { data: bannerBlockInfoData } = useGetBannerBlockInfoQuery(nodeId || skipToken)
  const fromQuery = params?.get(QueryParameters.From)
  const toQuery = params?.get(QueryParameters.To)
  const { data: queryStations } = useGetSearchFormStationQuery(
    fromQuery && toQuery ? { from: fromQuery, to: toQuery } : skipToken
  )

  const [stations, setStations] = useState<StationsForMapType>(() => {
    const initArrivalStation =
      queryStations?.arrival ||
      (bannerBlockInfoData?.arrival_station && getStationFormValues(bannerBlockInfoData.arrival_station))
    const initDepartureStation =
      queryStations?.departure ||
      (bannerBlockInfoData?.departure_station && getStationFormValues(bannerBlockInfoData.departure_station))
    return { arrival_station: initArrivalStation, departure_station: initDepartureStation }
  })

  useSubscribe(SubscribeEvents.SEARCH_STATIONS, (data: StationsForMapType) => {
    setStations(prevStation =>
      prevStation.arrival_station?.value !== data.arrival_station?.value ||
      prevStation.departure_station?.value !== data.departure_station?.value ||
      prevStation.layover_station?.value !== data.layover_station?.value
        ? data
        : prevStation
    )
  })

  return (
    <Wrapper>
      {Object.values(stations).filter(el => el?.id || el?.geolocation).length ? (
        <DynamicMap stations={stations} />
      ) : (
        <StaticMap />
      )}
    </Wrapper>
  )
}

export default memo(MapChart)
